@import 'colours';
@import 'fonts';
@import 'sharedStyles';

.navigation {
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:60px;
  z-index:500;
  background-color: @primary-color;
  background-position: right;
  border-bottom:1px solid rgba(0,0,0,0.15);
  font-size: 15px;
  font-family: @font-title;
}

.navigation-inner {
    padding-top: 10px;
}

.navigation-item {
  display:inline-block;
  height:40px;
  line-height:40px;
  text-decoration:none;
  margin:0px;
  padding:0px 20px;
  color:@navigation-text-color;
  .navigation-left & {
    float:left;
  }
}

.navigation-left {
	width:50%;
	float:left;
  .navigation-product-name {
    .unselectable;
  }
}

.navigation-right {
	width:47%;
  padding-right: 20px;
	float:right;
	text-align:right;

  .navigation-user-logout {
    padding: 0px 25px;
  }

  a {
    &:hover {
    text-decoration: underline;
      &.navigation-user-logout {
        text-decoration: none;
      }
    }
  }
}
